<!--<nz-content style="background-color: yellow">-->
<!--    <nz-carousel nzAutoPlay class="carousel-content">-->
<!--      <div nz-carousel-content *ngFor="let index of array"-->
<!--           class="carousel-content">-->
<!--        <img-->
<!--          nz-image-->
<!--          class="tab-img"-->
<!--          nzSrc="{{index[1]}}"-->
<!--          alt=""/>-->
<!--        <div class="overlay">-->
<!--          <div class="text2">  预告  </div>-->
<!--          <div class="text"> {{index[0]}}</div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </nz-carousel>-->
<!--  <div class="platform-main">-->
<!--    <span class="platform-title">Low Code + RPA + AI </span>-->
<!--  </div>-->
<!--  <audio src="assets/music/stareater.mp3" autoplay="autoplay" preload="auto" controls="controls" loop="loop"></audio>-->
<!--</nz-content>-->

<!--<div class="section" *ngFor="let section of sections">-->
<!--  <img [src]="section.imageUrl" alt="Section Image">-->
<!--</div>-->

<nz-carousel #carousel nzAutoPlay class="carousel-content" nzAutoPlaySpeed="5000"  >
  <div  nz-carousel-content *ngFor="let index of array"
       class="carousel-content">
    <img
      nz-image
      class="tab-img"
      nzSrc="{{index[1]}}"
      alt=""/>
  </div>
</nz-carousel>
<button  class="carousel-nav carousel-prev" (click)="prevSlide()">
  <span class="arrow-left"></span>
</button>
<button   class="carousel-nav carousel-next" (click)="nextSlide()">
  <span class="arrow-right"></span>
</button>
