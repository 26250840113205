export class Constants {

  public static SUCCESS_CODE = '200';

  public static URL_MOD_FORM = 'pages/dashboard';

  public static URL_MOD_LOGIN = 'login';

  public static KEY_LS_TOKEN = 'USER-TOKEN';

  public static KEY_LS_USER_SYS_ID = 'USER-SYS-ID';

  public static KEY_LS_USER_NAME = 'USER-NAME';

  public static KEY_LS_TENANT_UNION_ID = 'TENANT_UNION_ID';

  public static DEFAULT_USER_HEAER_IMG = 'ic_hunter_logo_new.png';

  public static USER_LOGO_IMG = 'ic_xfh_logo_v4_3.png';


  public static PHONE_NUMBER_REGEXP = /^1[3|4|5|7|8][0-9]\d{8}$/;


  // public static CHICK_LOG_ARY = [
  //   'assets/images/chick_026.png',
  //   'assets/images/chick_027.png',
  //   'assets/images/chick_028.png',
  //   'assets/images/chick_029.png',
  //   'assets/images/chick_035.png',
  //   'assets/images/chick_038.png',
  //   'assets/images/chick_007.png',
  //   'assets/images/chick_030.png',
  //   'assets/images/chick_012.png',
  //   'assets/images/chick_042.png'];

  public static CHICK_LOG_ARY = [
    'chick_026.png',
    'chick_027.png',
    'chick_028.png',
    'chick_029.png',
    'chick_035.png',
    'chick_038.png',
    'chick_007.png',
    'chick_030.png',
    'chick_012.png',
    'chick_042.png'];
}

