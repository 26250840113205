<!--<nz-content style="background-color: yellow">-->
    <nz-carousel nzAutoPlay class="carousel-content">
      <div nz-carousel-content *ngFor="let index of array"
           class="carousel-content">
        <img
          nz-image
          class="tab-img"
          nzSrc="{{index[1]}}"
          alt=""/>
        <div class="overlay">
          <div class="text2">  预告  </div>
          <div class="text"> {{index[0]}}</div>
        </div>
      </div>
    </nz-carousel>
  <div class="platform-main">
    <span class="platform-title">Low Code + RPA + AI </span>
  </div>
<!--  <audio src="assets/music/stareater.mp3" autoplay="autoplay" preload="auto" controls="controls" loop="loop"></audio>-->
<!--</nz-content>-->
