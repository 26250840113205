import { Component, OnInit } from '@angular/core';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-main-part',
  templateUrl: './main-part.component.html',
  styleUrls: ['./main-part.component.css']
})
export class MainPartComponent implements OnInit {

  isCollapsed = false;
  baseImageCommonUrl = environment.baseImageCommonUrl;
  array = [['  ～我变强了？？', this.baseImageCommonUrl + 'tab001.png'],
    ['  ～那是什么存在？？' , this.baseImageCommonUrl + 'tab002.png'],
      ['          ～我会飞！！？？' , this.baseImageCommonUrl + 'tab003.jpeg']];



  constructor() { }

  ngOnInit() {
  }
}
